.multi-repair-form {
  margin-top: 2rem;
  margin-bottom: 70px;
}

.multi-repair-form__btn {
  color: black;
}

.custom-btn {
  background: #fff;
  color: red;
  border: 2px solid red;
  border-radius: 3px;
  padding: 5px 10px;
  text-transform: uppercase;
}

* {
  box-sizing: border-box;
}

#address-search-input {
  background-image: url("./search-icon.png");
  background-position: 10px 12px;
  background-repeat: no-repeat;
  width: 100%;
  font-size: 16px;
  padding: 12px 20px 12px 40px;
  border: 1px solid black;
  margin-bottom: 12px;
}

#address-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

#address-list li a {
  border: 1px solid #ddd;
  margin-top: -1px; /* Prevent double borders */
  background-color: #f6f6f6;
  padding: 12px;
  text-decoration: none;
  font-size: 18px;
  color: black;
  display: block;
}

#address-list li a:hover:not(.header) {
  background-color: #eee;
}

input[class="appointment-input"],
select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: none;
  background: #fcfcfc;
  border-radius: 4px;
  box-sizing: border-box;
}

textarea[class="appointment-textarea"] {
  background: #fcfcfc;
}

input[class="appointment-submit"] {
  width: 100%;
  background-color: black;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[class="appointment-submit"]:hover {
  background-color: #242323;
}

.appointment-date {
  width: 100%;
  padding: 12px 20px;
  display: inline-block;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  box-sizing: border-box;
  background: #fcfcfc;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker__input-container > input {
  width: 100%;
}

.react-datepicker__input-container > input {
  border: none;
  background: inherit;
}

.css-2b097c-container {
  width: 100%;
}

.css-yk16xz-control {
  background-color: #fcfcfc !important;
  outline: none;
  border-style: none !important;
  padding: 12px 20px;
}

/* div {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
} */

.booking-detials {
  margin-top: 3rem;
}

.booking-detials > div {
  display: flex;
  justify-content: space-between;
}

.booking-detials > div > p {
  font-size: 20px;
}

@media screen and (max-width: 350px) {
  .booking-detials > div > p {
    font-size: 16px;
  }
}